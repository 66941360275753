@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: 'Red Hat Display', sans-serif;

    --gradient-text: linear-gradient(0deg, #205295 25%, #144272 50%, #2c74b3 75%);
    --gradient-bar: linear-gradient(350deg, #0a2647 0%, #205295 100%);

    --color-black: #000;
    --color-dark: #0A2647;
    --color-ldark: #144272;
    --color-llight: #205295;
    --color-light: #2c74b3;
    --color-white: #fff;
}