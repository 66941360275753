.gis__navbar {
    position: fixed;
    top: 1rem;
    left: 4rem;
    right: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;
    transition: 0.5s;
    z-index: 5;
}

.gis__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gis__navbar-links_logo {
    margin: 0rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gis__navbar-links_logo a {
    height: 100%;
    width: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gis__navbar-links_logo img {
    height: 100%;
    width: 100%;
}

.gis__navbar-links_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    gap: 4rem;
}

.gis__navbar-links_container p {
    font-family: var(--font-family);
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
    line-height: 2rem;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    cursor: pointer;
    transition: 0.5s;
}

.gis__navbar-links_container p:hover {
    color: var(--color-light);
    transition: 0.25s;
}

.gis__navbar-links_container span {
    height: 0.2rem;
    width: 0px;
    background: var(--color-light);
    visibility: hidden;
    display: block;
    margin: 0 auto;
}

#btn1:hover > #line1 {
    visibility: visible;
    width: 100%;
    transition: 0.25s;
}

#btn2:hover > #line2 {
    visibility: visible;
    width: 100%;
    transition: 0.25s;
}

#btn3:hover > #line3 {
    visibility: visible;
    width: 100%;
    transition: 0.25s;
}

#btn4:hover > #line4 {
    visibility: visible;
    width: 100%;
    transition: 0.25s;
}

#btn5:hover > #line5 {
    visibility: visible;
    width: 100%;
    transition: 0.25s;
}

#btn6:hover > #line6 {
    visibility: visible;
    width: 100%;
    transition: 0.25s;
}

.gis__navbar-enauczanie {
    margin: 0rem 4rem;
}

.gis__navbar-enauczanie a {
    cursor: default;
}

.gis__navbar-enauczanie button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background: var(--color-light);
    padding: 0.3rem 1rem;
    height: 2.5rem;
    font-weight: 500;
    border-radius: 1.5rem;
    cursor: pointer;
}

.gis__navbar-enauczanie img {
    height: 1.5rem;
}

.gis__navbar-enauczanie button:hover {
    background: var(--color-llight);
    transition: 0.25s;
}

.scroll-nav {
    background: var(--color-white);
    box-shadow: 5px 5px 10px;
    transition: 0.5s;
}

.scroll-nav > .gis__navbar-menu svg {
    color: var(--color-light);
}

.scroll-btn p {
    color: var(--color-light);
    transition: 0.5s;
}

.gis__navbar-menu {
    position: relative;
    margin-right: 2rem;
    margin-left: 1rem;
    height: 2.7rem;
    display: none;
    justify-content: center;
    align-items: center;
}

.gis__navbar-menu svg {
    cursor: pointer;
    color: #fff;
}

.gis__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-white);
    padding: 2rem;
    position: absolute;
    top: 1.8rem;
    right: 0rem;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 1rem;
    box-shadow: 5px 5px 10px black;
}

.gis__navbar-menu_container p {
    font-family: var(--font-family);
    color: var(--color-light);
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.gis__navbar-menu_container p:hover {
    text-decoration: underline;
}

.gis__navbar-menu_container-links-enauczanie {
    margin: 0rem 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
}

.gis__navbar-menu_container-links-enauczanie a {
    cursor: default;
}

.gis__navbar-menu_container-links-enauczanie button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background: var(--color-light);
    padding: 0.3rem 1rem;
    height: 1.7rem;
    font-weight: 500;
    border-radius: 1.5rem;
    cursor: pointer;
}

.gis__navbar-menu_container-links-enauczanie img {
    height: 1.2rem;
}

.gis__navbar-menu_container-links-enauczanie button:hover {
    background: var(--color-llight);
    transition: 0.25s;
}

@media screen and (max-width: 1730px) {
    .gis__navbar-links_logo {
        margin-right: 2rem
    }
    .gis__navbar-links_logo a {
        width: 8rem;
    }
    .gis__navbar-links_container {
        gap: 2rem;
    }
    .gis__navbar-enauczanie {
        margin-left: 2rem;
    }
}

@media screen and (max-width: 1470px) {
    .gis__navbar-links_logo a {
        width: 6rem;
    }
    .gis__navbar-links_container p {
        font-size: 1.2rem;
        line-height: 1.7rem;
        padding-top: 0.6rem;
        padding-bottom: 0.4rem;
    }
    .gis__navbar-enauczanie button {
        height: 2rem;
    }
    .gis__navbar-enauczanie img {
        height: 1.2rem;
    }
}

@media screen and (max-width: 1280px) {
    .gis__navbar-links_logo a {
        width: 4rem;
    }
    .gis__navbar-links_container p {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-top: 0.6rem;
        padding-bottom: 0.4rem;
    }
    .gis__navbar-enauczanie button {
        height: 1.8rem;
    }
    .gis__navbar-enauczanie img {
        height: 1rem;
    }
}

@media screen and (max-width: 1130px) {
    .gis__navbar-links_logo {
        margin-left: 2rem;
    }
    .gis__navbar-menu {
        display: flex;
    }
    .gis__navbar-links_container {
        display: none;
    }
    .gis__navbar-enauczanie {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .gis__navbar {
        left: 2rem;
        right: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .gis__navbar {
        top: 0rem;
        left: 0rem;
        right: 0rem;
        border-radius: 0;
    }
    .gis__navbar-links_logo {
        margin-left: 1rem;
    }
    .gis__navbar-menu {
        margin-right: 1rem;
    }
}