.gis__sm {
    position: fixed;
    top: 25rem;
    transform: translateY(-50%);
    right: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 2.5rem;
    height: 7.5rem;
    z-index: 2;
    transition: 0.5s;
}

.gis__sm-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.gis__sm-container_fb {
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.8rem;
    align-items: center;
    align-self: flex-end;
    height: 100%;
    width: 2.5rem;
    background: var(--color-light);
    cursor: pointer;
    transition: 0.5s;
}

.gis__sm-container_yt {
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.45rem;
    align-items: center;
    align-self: flex-end;
    height: 100%;
    width: 2.5rem;
    background: var(--color-ldark);
    cursor: pointer;
    transition: 0.5s;
}

.gis__sm-container_ig {
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.55rem;
    align-items: center;
    align-self: flex-end;
    height: 100%;
    width: 2.5rem;
    background: var(--color-llight);
    cursor: pointer;
    transition: 0.5s;
}

.gis__sm-container_fb p {
    font-family: var(--font-family);
    padding-left: 0.8rem;
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-white);
    display: none;
}

.gis__sm-container_yt p {
    font-family: var(--font-family);
    padding-left: 0.45rem;
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-white);
    display: none;
}

.gis__sm-container_ig p {
    font-family: var(--font-family);
    padding-left: 0.55rem;
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-white);
    display: none;
}

.gis__sm:hover {
    width: 13rem;
}

.gis__sm-container_fb:hover {
    padding-left: 0.8rem;
    width: 84%;
    justify-content: flex-start;
    transition: 0.5s;
}

.gis__sm-container_yt:hover {
    padding-left: 0.45rem;
    width: 100%;
    justify-content: flex-start;
    transition: 0.5s;
}

.gis__sm-container_ig:hover {
    padding-left: 0.55rem;
    width: 97%;
    justify-content: flex-start;
    transition: 0.5s;
}

.gis__sm-container_fb:hover > p {
    display: flex;
}

.gis__sm-container_yt:hover > p {
    display: flex;
}

.gis__sm-container_ig:hover > p {
    display: flex;
}

/*@media screen and (max-width: 500px) {
    .gis__sm {
    display: none;
    }
}*/