.gis__register {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8rem;
    transform: translateY(-50%);
    right: 0rem;
    z-index: 3;
    width: fit-content;
    height: fit-content;
    transition: 0.5s;
}

.gis__register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.5rem;
    padding-left: 1rem;
    width: fit-content;
    height: 4rem;
    border: 0;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background: #B30901;
    box-shadow: 5px 5px 10px #000;
    transition: 0.5s;
    cursor: pointer;
}

.gis__register-container:hover {
    background: #CE0E00;
    transition: 0.25s;
}

.gis__register h1 {
    font-family: var(--font-family);
    color: var(--color-white);
    font-weight: 800;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
}

@media screen and (max-width: 1100px) {
    .gis__register {
        top: 12rem;
        transform: translateY(-50%) rotate(-90deg);
        right: -3.1rem;
        transition: 0.5s;
    }

    .gis__register-container {
        border-bottom-left-radius: 0;
        border-top-right-radius: 1.5rem;
        box-shadow: -5px 5px 10px #000;
    }
}

@media screen and (max-width: 700px) {
    .gis__register {
        right: -4.1rem;
    }
    .gis__register-container {
        height: 2.5rem;
        padding: 0rem 1rem;
    }
}