.gis__lecturer {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

.anchor-lecturer {
    display: block;
    position: absolute;
    top: -5rem;
    left: 0rem;
    visibility: hidden;
    z-index: -1;
}

.gis__lecturer-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
}

.gis__lecturer-heading-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border: 0;
    border-radius: 2rem;
    background: var(--gradient-text);
}

.gis__lecturer-heading-pin p {
    font-size: 1.8rem;
    color: var(--color-white);
    line-height: 2rem;
}

.gis__lecturer-heading-text {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 4rem;
}

.gis__lecturer-panel {
    display: flex;
    width: 100%;
    height: fit-content;
}

.gis__lecturer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 0.5rem solid var(--color-llight);
    border-radius: 2.5rem;
    width: 100%;
    height: 100%;
    box-shadow: 5px 5px 10px;
}

.gis__lecturer-container_name {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-llight);
    border: 0;
    border-radius: 1.5rem;
    height: fit-content;
    padding: 0.5rem 1rem;
    width: 93%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.gis__lecturer-container_name h2 {
    font-family: var(--font-family);
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-white);
    width: 100%;
    text-align: center;
}

.gis__lecturer-container_description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
}

.description__lecturer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 93%;
    margin-bottom: 1rem;
}

.description__photo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    min-width: 8rem;
    max-width: 8rem;
    border: 0;
    border-radius: 4rem;
    margin-right: 1rem;
}

.description__photo img {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 4rem;
}

.description__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.description__name h3 {
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 900;
    font-size: 1.5rem;
}

.description__name h4 {
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 900;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.description__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 93%;
}

.description__text h5 {
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 900;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.description__text h5:last-of-type {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.description__text p {
    font-family: var(--font-family);
    color: var(--color-black);
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: justify;
}

.description__text textarea {
    font-family: var(--font-family);
    color: var(--color-black);
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: justify;
    border: 0;
    outline: 0;
    width: 100%;
    height: 6rem;
    resize: none;
    padding-right: 0.5rem;
    cursor: auto;
}

textarea::-webkit-scrollbar {
    width: 10px;
}

textarea::-webkit-scrollbar-thumb {
    background: var(--color-llight);
    border: 0;
    border-radius: 5px;
}

textarea::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 5px;
}

.description__panel {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
}

.description__text-panel {
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.description__text-panel a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    border: 0;
    border-radius: 1rem;
}

.description__text h6 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    padding-left: 0.2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border: 0.15rem solid var(--color-llight);
    border-radius: 1rem;
}

.description__text h6:hover {
    box-shadow: inset 0px 0px 10px var(--color-llight);
}

.mostWiedzy {
    color: #1A3966;
}

.description__text img {
    height: 1.5rem;
    margin-right: 0.5rem;
}

.slide {
    padding: 1.5rem 1.5rem;
}

@media screen and (max-width: 1700px) {
    .gis__lecturer-heading-pin {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1.75rem;
    }
    
    .gis__lecturer-heading-pin p {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    
    .gis__lecturer-heading-text {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 1450px) {
    .gis__lecturer-heading-pin {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
    }
    
    .gis__lecturer-heading-pin p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .gis__lecturer-heading-text {
        font-size: 3rem;
    }
}

@media screen and (max-width: 1250px) {
    .gis__lecturer-heading-pin {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
    }
    
    .gis__lecturer-heading-pin p {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .gis__lecturer-heading-text {
        font-size: 2.5rem;
    }

    .gis__lecturer-container_name h2 {
        font-size: 1.5rem;
    }

    .description__photo {
        min-width: 6rem;
        max-width: 6rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__lecturer {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .gis__lecturer-container_name {
        border-radius: 1.5rem;
    }

    .gis__lecturer-container_name h2 {
        font-size: 1.4rem;
    }
    .description__photo {
        min-width: 5rem;
        max-width: 5rem;
    }
    .description__name h3 {
        font-size: 1.3rem;
    }
    
    .description__name h4 {
        font-size: 1rem;
    }
    
    .description__text h5 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    
    .description__text p {
        font-size: 0.9rem;
    }

    /*.description__text textarea {
        font-size: 0.9rem;
    }*/

    .slide {
        padding: 1.5rem 0.75rem;
    }
}

@media screen and (max-width: 950px) {
    .gis__lecturer {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .gis__lecturer-container_name {
        border-radius: 1.5rem;
        width: 90%;
    }

    .gis__lecturer-container_name h2 {
        font-size: 1.2rem;
    }

    .description__panel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .description__name h3 {
        word-break: break-all;
    }
    
    .description__name h4 {
        word-break: break-all;
    }
}

@media screen and (max-width: 700px) {
    .gis__lecturer {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .description__panel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 500px) {
    .gis__lecturer {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}