.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}

.left-arrow {
    left: -1rem;
    border: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    outline: 0;
    background: var(--color-llight);
    opacity: 1;
    width: 2.9rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
}

.right-arrow {
    right: -1rem;
    border: 0;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    outline: 0;
    background: var(--color-llight);
    opacity: 1;
    width: 2.9rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.left-arrow p, .right-arrow p {
    font-family: var(--font-family);
    font-size: 2rem;
    line-height: 2rem;
    transform: translateX(-10%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
}

.left-arrow p {
    transform: translateX(-10%);
}

.right-arrow p {
    transform: translateX(10%);
}

.left-arrow:hover {
    background: var(--color-ldark);
    transition: 0.5s;
}

.right-arrow:hover {
    background: var(--color-ldark);
    transition: 0.5s;
}
/*
.indicators {
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicators > div {
    border: 0;
    border-radius: 0.7rem;
    outline: 0;
    background: var(--color-light);
    opacity: 0.8;
    width: 1rem;
    height: 1rem;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.indicators > div:hover {
    opacity: 1;
    transition: 0.5s;
}

.indicators > div.active {
    background-color: var(--color-llight);
    width: 2rem;
    transition: 0.5s;
    opacity: 1;
    position: absolute;
}*/

@media screen and (max-width: 1100px) {
    .left-arrow {
        left: -1.75rem;
    }
    .right-arrow {
        right: -1.75rem;
    }
}

@media screen and (max-width: 700px) {
    .left-arrow {
        width: 2rem;
        left: 0rem;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
    .right-arrow {
        width: 2rem;
        right: 0rem;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}