.gis__about {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    padding-left: 8rem;
    padding-right: 8rem;
}

.anchor-about {
    display: block;
    position: absolute;
    top: -5rem;
    left: 0rem;
    visibility: hidden;
    z-index: -1;
}

.gis__about-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 2rem;
}

.gis__about-heading-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border: 0;
    border-radius: 2rem;
    background: var(--gradient-text);
}

.gis__about-heading-pin p {
    font-size: 2rem;
    color: var(--color-white);
    line-height: 2rem;
}

.gis__about-heading-text {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 4rem;
}

.gis__about-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 4rem;
    width: 100%;
}

.gis__about-container_image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.gis__about-container_image img {
    width: 100%;
    border: 0.5rem solid var(--color-llight);
    border-radius: 2.5rem;
    box-shadow: 5px 5px 10px;
}

.gis__about-container_description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.description__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.description__top h1 {
    font-family: var(--font-family);
    color: var(--color-dark);
    font-weight: 900;
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

.description__top h2 {
    font-family: var(--font-family);
    color: var(--color-ldark);
    font-weight: 900;
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.description__top h3 {
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 900;
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.description__top p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.2rem;
    text-align: justify;
}

.description__bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.description__bottom-time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 13.5rem;
}

.description__bottom-time p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
}

.description__bottom-time h4 {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    font-weight: 900;
    color: var(--color-llight);
    font-size: 2.5rem;
}

.description__bottom-price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 13.5rem;
}

.description__bottom-price p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
}

.description__bottom-price h4 {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 900;
    font-size: 2.5rem;
}

.description__bottom-register {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 13.5rem;
}

.description__bottom-register p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 0.55rem;
}

.description__bottom-register a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 1.5rem;
}

.description__bottom-register button {
    font-family: var(--font-family);
    font-weight: 900;
    color: var(--color-white);
    font-size: 2rem;
    line-height: 3rem;
    outline: 0;
    border: 0;
    border-radius: 1.5rem;
    background: #B30901;
    padding: 0rem 1.5rem;
    cursor: pointer;
}

.description__bottom-register button:hover {
    background: #CE0E00;
    transition: 0.25s;
}

@media screen and (max-width: 1700px) {
    .gis__about-heading-pin {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1.75rem;
    }
    
    .gis__about-heading-pin p {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    
    .gis__about-heading-text {
        font-size: 3.5rem;
    }
    .description__top h1 {
        font-size: 2.5rem;
    }
    
    .description__top h2 {
        font-size: 2.5rem;
    }
    
    .description__top h3 {
        font-size: 2.5rem;
    }
    
    .description__top p {
        font-size: 1.1rem;
    }
    .description__bottom-time {
        min-width: 12.5rem;
    }
    .description__bottom-time p {
        font-size: 1.1rem;
    }
    
    .description__bottom-time h4 {
        font-size: 2.2rem;
    }
    
    .description__bottom-price {
        min-width: 12.5rem;
    }
    
    .description__bottom-price p {
        font-size: 1.1rem;
    }
    
    .description__bottom-price h4 {
        font-size: 2.2rem;
    }
    
    .description__bottom-register {
        min-width: 12.5rem;
    }
    
    .description__bottom-register p {
        font-size: 1.1rem;
    }
    
    .description__bottom-register button {
        font-size: 1.7rem;
        line-height: 2.7rem;
    }
}

@media screen and (max-width: 1450px) {
    .gis__about-heading-pin {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
    }
    
    .gis__about-heading-pin p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .gis__about-heading-text {
        font-size: 3rem;
    }
    .description__top h1 {
        font-size: 2rem;
    }
    
    .description__top h2 {
        font-size: 2rem;
    }
    
    .description__top h3 {
        font-size: 2rem;
    }
    
    .description__top p {
        font-size: 1rem;
    }
    .description__bottom-time {
        min-width: 11.5rem;
    }
    .description__bottom-time p {
        font-size: 1rem;
    }
    
    .description__bottom-time h4 {
        font-size: 2rem;
    }
    
    .description__bottom-price {
        min-width: 11.5rem;
    }
    
    .description__bottom-price p {
        font-size: 1rem;
    }
    
    .description__bottom-price h4 {
        font-size: 2rem;
    }
    
    .description__bottom-register {
        min-width: 11.5rem;
    }
    
    .description__bottom-register p {
        font-size: 1rem;
    }
    
    .description__bottom-register button {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}

@media screen and (max-width: 1250px) {
    .gis__about-heading-pin {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
    }
    
    .gis__about-heading-pin p {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .gis__about-heading-text {
        font-size: 2.5rem;
    }
    .description__top h1 {
        font-size: 1.5rem;
    }
    
    .description__top h2 {
        font-size: 1.5rem;
    }
    
    .description__top h3 {
        font-size: 1.5rem;
    }
    
    .description__top p {
        font-size: 0.9rem;
    }
    .description__bottom-time {
        min-width: 10.5rem;
    }
    .description__bottom-time p {
        font-size: 0.9rem;
    }
    
    .description__bottom-time h4 {
        font-size: 1.5rem;
    }
    
    .description__bottom-price {
        min-width: 10.5rem;
    }
    
    .description__bottom-price p {
        font-size: 0.9rem;
    }
    
    .description__bottom-price h4 {
        font-size: 1.5rem;
    }
    
    .description__bottom-register {
        min-width: 10.5rem;
    }
    
    .description__bottom-register p {
        font-size: 0.9rem;
    }
    
    .description__bottom-register button {
        font-size: 1.25rem;
        line-height: 2rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__about {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    
    .gis__about-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        width: 100%;  
    }
    .gis__about-container_image {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }
    
    .gis__about-container_image img {
        width: 100%;
        border: 0.5rem solid var(--color-llight);
        border-radius: 2.5rem;
        box-shadow: 5px 5px 10px;
    }

    .gis__about-container_description {
        width: 100%
    }

    .description__bottom-time {
        min-width: 7rem;
    }
    
    .description__bottom-price {
        min-width: 8.5rem;
    }
}

@media screen and (max-width: 950px) {
    .gis__about-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;  
    }
    .gis__about-container_image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
    }

    .description__bottom-time {
        min-width: 7rem;
    }
    
    .description__bottom-price {
        min-width: 8.5rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__about {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .gis__about-container_image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
    }
    
    .gis__about-container_image img {
        width: 100%;
        border: 0.5rem solid var(--color-llight);
        border-radius: 2.5rem;
        box-shadow: 5px 5px 10px;
    }

    .gis__about-container_description {
        width: 100%
    }

    .description__bottom {
        margin-top: 1rem;
        width: 100%;
    }

}

@media screen and (max-width: 550px) {
    .description__bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .description__bottom-time {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .description__bottom-price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .description__bottom-register {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 500px) {
    .gis__about {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}