.gis__more {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 13rem;
    transform: translateY(-50%);
    right: 0rem;
    z-index: 2;
    width: fit-content;
    height: fit-content;
    transition: 0.5s;
}

.gis__more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.5rem;
    padding-left: 1rem;
    width: 10.2rem;
    height: 4rem;
    border: 0;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background: var(--color-llight);
    box-shadow: 5px 5px 10px #000;
    transition: 0.5s;
    cursor: pointer;
}

.gis__more-container:hover {
    background: var(--color-light);
    transition: 0.25s;
}

.gis__more h1 {
    font-family: var(--font-family);
    color: var(--color-white);
    font-weight: 800;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
}

@media screen and (max-width: 1100px) {
    .gis__more {
        top: 12rem;
        transform: translateY(-50%) rotate(-90deg);
        transition: 0.5s;
    }

    .gis__more-container {
        border-bottom-left-radius: 0;
        border-top-right-radius: 1.5rem;
        box-shadow: -5px 5px 10px #000;
        height: 8rem;
        padding-bottom: 2.75rem;
        text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .gis__more {
        right: -1.25rem;
    }
    .gis__more-container {
        height: 7rem;
        width: 10.6rem;
        padding: 0rem 1rem;
        padding-bottom: 2rem;
    }
}