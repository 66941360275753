.gis__testimonials {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    padding-left: 8rem;
    padding-right: 8rem;
}

.testimonials-header h1 {
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 900;
    font-size: 3rem;
    margin-bottom: 2rem;
}

.testimonials-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
}

.testimonial-card {
    background-color: #fff;
    border: 0.5rem solid var(--color-llight);
    border-radius: 2.5rem;
    padding: 1.5rem 1.5rem;
    width: 100%;
    text-align: left;
}

.stars {
    font-family: var(--font-family);
    color: #FFC94A; /* Złoty kolor dla gwiazdek */
    font-size: 1.5rem;
}

.review {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.2rem;
    margin: 1rem 0;
}

.name {
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 1.5rem;
  color: #333;
}

@media screen and (max-width: 1700px) {
    .testimonials-header h1 {
        font-size: 2.5rem;
    }
    .stars {
        font-size: 1.4rem;
    }
    .review {
        font-size: 1.1rem;
    }
    .name {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1450px) {
    .testimonials-header h1 {
        font-size: 2rem;
    }
    .stars {
        font-size: 1.25rem;
    }
    .review {
        font-size: 1rem;
    }
    .name {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1250px) {
    .testimonials-header h1 {
        font-size: 1.5rem;
    }
    .stars {
        font-size: 1.15rem;
    }
    .review {
        font-size: 0.9rem;
    }
    .name {
        font-size: 1.15rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__testimonials {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .testimonials-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
    .testimonials-header h1 {
        font-size: 1.5rem;
    }
    .stars {
        font-size: 1.15rem;
    }
    .review {
        font-size: 0.9rem;
    }
    .name {
        font-size: 1.15rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__testimonials {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .testimonials-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .gis__testimonials {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}