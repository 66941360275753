.gis__backtop {
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 2.5rem;
    right: 2.5rem;
    width: 3rem;
    height: 3rem;
    z-index: 1;
}

.backtop-vis {
    display: flex;
}

.gis__backtop a {
    cursor: default;
}

.gis__backtop-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    transform: rotate(90deg);
    background: var(--color-light);
    border: 0;
    outline: 0;
    border-radius: 1.5rem;
    cursor: pointer;
}

.gis__backtop-arrow p {
    font-size: 2rem;
    line-height: 2rem;
    color: var(--color-white);
}

.gis__backtop-arrow:hover {
    background: var(--color-llight);
    transition: 0.25s;
}

@media screen and (max-width: 1100px) {
    .gis__backtop {
        right: 1.5rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__backtop {
        right: 0.5rem;
    }
}

/*@media screen and (max-width: 500px) {
    .gis__backtop {
    display: none;
    }
}*/