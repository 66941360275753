.gis__header {
    position: relative;
    display: flex;
    background: var(--color-dark);
}

.gis__header-content {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.gis__header-content h1 {
    top: 33%;
    left: 8rem;
    transform: translateY(-50%);
    position: absolute;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 3.4rem;
    line-height: 4.4rem;
    color: var(--color-white);
}

.gis__header-content h2 {
    top: 50%;
    left: 8rem;
    transform: translateY(-50%);
    position: absolute;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 3rem;
    line-height: 4rem;
    color: var(--color-white);
}

.gis__header-content svg {
    width: 100%;
    height: 100%;
}

.gis__header-content-svg_right {
    transform: scaleX(-1);
}

@media screen and (max-width: 1700px) {
    .gis__header-content h1 {
        font-size: 2.8rem;
        line-height: 3.8rem;
    }
    .gis__header-content h2 {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }
}

@media screen and (max-width: 1450px) {
    .gis__header-content h1 {
        font-size: 2.3rem;
        line-height: 3.3rem;
    }

    .gis__header-content h2 {
        font-size: 2.05rem;
        line-height: 3.05rem;
    }
}

@media screen and (max-width: 1250px) {
    .gis__header-content h1 {
        font-size: 1.95rem;
        line-height: 2.95rem;
    }

    .gis__header-content h2 {
        font-size: 1.75rem;
        line-height: 2.75rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__header {
        padding-top: 5rem;
        position: relative;
        display: flex;
    }

    .gis__header-content h1 {
        top: 20%;
        left: 6rem;
        font-size: 1.95rem;
        line-height: 3rem;
        height: fit-content;
    }

    .gis__header-content h2 {
        top: 45%;
        left: 6rem;
        font-size: 1.75rem;
        line-height: 2.75rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__header {
        padding-top: 10rem;
        position: relative;
        display: flex;
    }

    .gis__header-content h1 {
        top: 0;
        left: 4rem;
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

    .gis__header-content h2 {
        top: 35%;
        left: 4rem;
        font-size: 1.35rem;
        line-height: 2.3rem;
    }

}

@media screen and (max-width: 500px) {
    .gis__header {
        padding-top: 15rem;
        position: relative;
        display: flex;
    }

    .gis__header-content h1 {
        top: -60%;
        left: 1.25rem;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .gis__header-content h2 {
        top: 10%;
        left: 1.25rem;
        font-size: 1.35rem;
        line-height: 2.35rem;
    }
}