.gis__footer {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30rem;
    margin-top: 10rem;
}

.gis__footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    height: 25rem;
    background: var(--color-ldark);
    padding-left: 8rem;
    padding-right: 8rem;
    grid-gap: 3rem;
}

.gis__footer-contactinfo {
    width: 100%;
    height: 21rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-left: 0.15rem solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.gis__footer-contactinfo h1 {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 1.2rem;
    color: var(--color-white);
    margin-bottom: 0.75rem;
}

.gis__footer-contactinfo h3 {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 1rem;
    color: var(--color-white);
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
}

.gis__footer-contactinfo span {
    margin-right: 0.5rem;
}

.gis__footer-contactinfo p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--color-white);
}

.p-mail:hover {
    text-decoration: underline;
}

.p-tel:hover {
    text-decoration: underline;
}

.gis__footer-links {
    width: 100%;
    height: 21rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-left: 0.15rem solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.gis__footer-links h1 {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 1.2rem;
    color: var(--color-white);
    margin-bottom: 0.75rem;
}

.gis__footer-links h1:last-of-type {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 1.2rem;
    color: var(--color-white);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.gis__footer-links p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--color-white);
}

.gis__footer-links p:hover {
    text-decoration: underline;
}

.gis__footer-pg {
    width: 100%;
    height: 21rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-left: 0.15rem solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.gis__footer-pg a {
    margin-bottom: 1.5rem;
}

.gis__footer-pg a:hover {
    opacity: 0.6;
}

.gis__footer-pg img {
    height: 5rem;
}

#logo-last {
    margin-bottom: 0;
}

.gis__footer-copyright {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5rem;
    background: var(--color-dark);
    padding-left: 8rem;
    padding-right: 8rem;
    box-shadow: inset 0 0 10px var(--color-black);
}

.gis__footer-copyright-enauczanie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 10rem;
}

.gis__footer-copyright-enauczanie button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background: transparent;
    height: 1.5rem;
    cursor: pointer;
}

.gis__footer-copyright-enauczanie button:hover {
    opacity: 0.6;
    transition: 0.25s;
}

.gis__footer-copyright-enauczanie img {
    height: 1.5rem;
}

.gis__footer-copyright-design {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gis__footer-copyright-design h2 {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    line-height: 1rem;
}

.gis__footer-copyright-sm {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;
    flex-direction: row;
    font-size: 1.5rem;
    color: var(--color-white);
    width: 10rem;
}

.gis__footer-copyright-sm_fb:hover {
    opacity: 0.6;
    transition: 0.25s;
}

.gis__footer-copyright-sm_ig:hover {
    opacity: 0.6;
    transition: 0.25s;
}

.gis__footer-copyright-sm_yt:hover {
    opacity: 0.6;
    transition: 0.25s;
}

@media screen and (max-width: 1250px) {

    .gis__footer-contactinfo {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .gis__footer-contactinfo h1 {
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 0.4rem;
    }
    
    .gis__footer-contactinfo h3 {
        font-weight: 600;
        font-size: 1rem;
        margin-top: 0.5rem;
    }
    
    .gis__footer-contactinfo p {
        font-weight: 300;
        font-size: 0.9rem;
    }
    
    .gis__footer-links {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .gis__footer-links h1 {
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 0.4rem;
    }
    
    .gis__footer-links h1:last-of-type {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }
    
    .gis__footer-links p {
        font-weight: 300;
        font-size: 0.9rem;
    }

    .gis__footer-pg {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__footer-container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .gis__footer-copyright {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .gis__footer-copyright-design h2 {
        font-size: 1rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 950px) {
    .gis__footer-container {
        grid-gap: 1rem;
    }
    .gis__footer-contactinfo {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .gis__footer-links {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .gis__footer-pg {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .gis__footer-copyright-design h2 {
        font-size: 0.9rem;
        font-weight: 300;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 750px) {
    .gis__footer {
        height: fit-content;
    }
    .gis__footer-container{
        grid-template-columns: repeat(2, 1fr);
        height: fit-content;
    }
    .gis__footer-pg {
        grid-column: 1 / 3;
        width: 100%;
        height: 5.5rem;
        margin-top: 0;
        margin-bottom: 2rem;
        border-left: 0.15rem solid transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

@media screen and (max-width: 700px) {
    .gis__footer-container {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .gis__footer-copyright {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .gis__footer-copyright-enauczanie {
        width: 9rem;
    }
    
    .gis__footer-copyright-enauczanie button {
        height: 1.2rem;
    }

    .gis__footer-copyright-enauczanie img {
        height: 1.2rem;
    }

    .gis__footer-copyright-sm {
        gap: 1rem;
        font-size: 1.2rem;
        color: var(--color-white);
        width: 6.5rem;
    }
}

@media screen and (max-width: 500px) {
    .gis__footer-container {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .gis__footer-contactinfo {
        height: 22.5rem;
    }

    .gis__footer-links {
        height: 22.5rem;
    }

    .gis__footer-copyright {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        height: fit-content;
    }
}