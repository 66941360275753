.gis__gallery {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    padding-left: 8rem;
    padding-right: 8rem;
}

.anchor-gallery {
    display: block;
    position: absolute;
    top: -5rem;
    left: 0rem;
    visibility: hidden;
    z-index: -1;
}

.gis__gallery-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 2rem;
}

.gis__gallery-heading-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border: 0;
    border-radius: 2rem;
    background: var(--gradient-text);
}

.gis__gallery-heading-pin p {
    font-size: 1.8rem;
    color: var(--color-white);
    line-height: 2rem;
}

.gis__gallery-heading-text {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 4rem;
}

.gis__gallery-panel {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
}

.gis__gallery-panel-container {
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    box-shadow: 5px 5px 10px var(--color-black);
    cursor: pointer;
}

.gallery-photo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.gallery-photo img {
    width: 100%;
}

.gallery-photo iframe {
    width: 100%;
}

.gis__gallery-panel-container:nth-child(n+7) {
    display: none;
}

.gallery-image-0 {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: var(--color-black);
}

.gallery-image-1 {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: var(--color-black);
}

.gallery-image-2 {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: var(--color-black);
}

.gallery-image-3 {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: var(--color-black);
}

.gallery-image-4 {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: var(--color-black);
}

.gallery-image-5 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    color: var(--color-white);
}

.gallery-image-5 h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: 0.3rem;
}

.blur-effect {
    filter: blur(0.5rem);
}

.pseudoserver {
    display: none;
}

@media screen and (max-width: 1700px) {
    .gis__gallery-heading-pin {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1.75rem;
    }
    
    .gis__gallery-heading-pin p {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    
    .gis__gallery-heading-text {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 1450px) {
    .gis__gallery-heading-pin {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
    }
    
    .gis__gallery-heading-pin p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .gis__gallery-heading-text {
        font-size: 3rem;
    }

    .gis__gallery-panel {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }
}

@media screen and (max-width: 1250px) {
    .gis__gallery-heading-pin {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
    }
    
    .gis__gallery-heading-pin p {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .gis__gallery-heading-text {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__gallery {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .gis__gallery-panel {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
    .gallery-image-5 h2 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 950px) {
    .gis__gallery {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .gallery-image-5 h2 {
        font-size: 1.7rem;
    }
    .gallery-photo iframe {
        width: 100%;
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gis__gallery {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .gallery-image-5 h2 {
        font-size: 1.2rem;
    }
    .gallery-photo iframe {
        width: 100%;
        height: 200px;
    }
}

@media screen and (max-width: 500px) {
    .gis__gallery {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .gis__gallery-panel {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
    .gallery-image-5 h2 {
        font-size: 1rem;
    }
    .gallery-photo iframe {
        width: 100%;
        height: 300px;
    }
}