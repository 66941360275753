.gis__location {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    padding-left: 8rem;
    padding-right: 8rem;
}

.anchor-location {
    display: block;
    position: absolute;
    top: -5rem;
    left: 0rem;
    visibility: hidden;
    z-index: -1;
}

.gis__location-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 2rem;
}

.gis__location-heading-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border: 0;
    border-radius: 2rem;
    background: var(--gradient-text);
}

.gis__location-heading-pin p {
    font-size: 2rem;
    color: var(--color-white);
    line-height: 2rem;
}

.gis__location-heading-text {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 4rem;
}

.gis__location-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    width: 100%;
}

.gis__location-container_map {
    display: flex;
}

.gis__location-container_map iframe {
    width: 100%;
    height: 30rem;
    border: 0.5rem solid var(--color-llight);
    border-radius: 2.5rem;
    box-shadow: 5px 5px 10px;
}

.gis__location-container_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.gis__location-container_description h1 {
    font-family: var(--font-family);
    color: var(--color-dark);
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 2rem;
}

.gis__location-container_description h2 {
    font-family: var(--font-family);
    color: var(--color-ldark);
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 2rem;
}

.gis__location-container_description h3 {
    font-family: var(--font-family);
    color: var(--color-llight);
    font-weight: 500;
    font-size: 2.7rem;
}

@media screen and (max-width: 1700px) {
    .gis__location-container {
        grid-template-columns: 1fr 1fr;
    }

    .gis__location-heading-pin {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1.75rem;
    }
    
    .gis__location-heading-pin p {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    
    .gis__location-heading-text {
        font-size: 3.5rem;
    }
    
    .gis__location-container_description h1 {
        font-size: 3.5rem;
        margin-bottom: 2rem;
    }
    
    .gis__location-container_description h2 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
    
    .gis__location-container_description h3 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 1450px) {
    .gis__location-container {
        grid-template-columns: 4fr 5fr;
    }

    .gis__location-container_map iframe {
        height: 25rem;
    }

    .gis__location-heading-pin {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
    }
    
    .gis__location-heading-pin p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .gis__location-heading-text {
        font-size: 3rem;
    }

    .gis__location-container_description h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }
    
    .gis__location-container_description h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    
    .gis__location-container_description h3 {
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 1250px) {
    .gis__location-container {
        grid-template-columns: 1fr 2fr;
    }

    
    .gis__location-container_map iframe {
        height: 20rem;
    }

    .gis__location-heading-pin {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
    }
    
    .gis__location-heading-pin p {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .gis__location-heading-text {
        font-size: 2.5rem;
    }

    .gis__location-container_description h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
    
    .gis__location-container_description h2 {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
    
    .gis__location-container_description h3 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__location {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    
    .gis__location-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
    }

    
    .gis__location-container_map iframe {
        height: 25rem;
    }
}

@media screen and (max-width: 800px) {
    .gis__location-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .gis__location-container_map {
        width: 100%;
    }

    .gis__location-container_description {
        width: 100%;
    }

    .gis__location-container_map iframe {
        height: 20rem;
    }

    .description__bottom-time {
        min-width: 7rem;
    }
    
    .description__bottom-price {
        min-width: 8.5rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__location {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .description__bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .description__bottom-time {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .description__bottom-price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .description__bottom-register {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 500px) {
    .gis__location {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}