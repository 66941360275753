.gis__subjects {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2.5rem;
    padding-left: 8rem;
    padding-right: 8rem;
}

.anchor-subjects {
    display: block;
    position: absolute;
    top: -5rem;
    left: 0rem;
    visibility: hidden;
    z-index: -1;
}

.gis__subjects-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 2rem;
}

.gis__subjects-heading-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border: 0;
    border-radius: 2rem;
    background: var(--gradient-text);
}

.gis__subjects-heading-pin p {
    font-size: 2rem;
    color: var(--color-white);
    line-height: 2rem;
}

.gis__subjects-heading-text {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 4rem;
}

.gis__subjects-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.about-subject {
    width: 65%;
}

.about-subject h2 {
    font-family: var(--font-family);
    font-weight: 900;
    margin-bottom: 0.5rem;
    color: var(--color-llight);
    font-size: 1.8rem;
}

.about-subject p {
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--color-black);
    font-size: 1.1rem;
}

.hours-subject {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    border-left: 0.3rem solid gray;
}

.hours-subject-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.hours-subject-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.hours-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.hours-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.hours-subject h2 {
    font-family: var(--font-family);
    font-weight: 900;
    margin-bottom: 0.5rem;
    color: var(--color-llight);
    font-size: 1.8rem;
    padding-left: 1.5rem;
}

.hours-subject h3 {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-ldark);
    font-size: 1.4rem;
    padding-left: 1.5rem;
}

.hours-subject p {
    font-family: var(--font-family);
    font-weight: 900;
    color: var(--color-black);
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
}

@media screen and (max-width: 1700px) {
    .gis__subjects-heading-pin {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1.75rem;
    }
    
    .gis__subjects-heading-pin p {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    
    .gis__subjects-heading-text {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 1450px) {
    .gis__subjects-heading-pin {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
    }
    
    .gis__subjects-heading-pin p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .gis__subjects-heading-text {
        font-size: 3rem;
    }
}

@media screen and (max-width: 1250px) {
    .gis__subjects-heading-pin {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
    }
    
    .gis__subjects-heading-pin p {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .gis__subjects-heading-text {
        font-size: 2.5rem;
    }

    .about-subject {
        width: 65%;
    }
    
    .about-subject h2 {
        font-size: 1.5rem;
    }
    
    .about-subject p {
        font-size: 1rem;
    }
    
    .hours-subject {
        width: 12rem;
    }
    
    .hours-subject h2 {
        font-size: 1.5rem;
        padding-left: 1rem;
    }
    
    .hours-subject h3 {
        font-size: 1.2rem;
        padding-left: 1rem;
    }
    
    .hours-subject p {
        font-size: 1rem;
        padding-left: 1rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__subjects {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media screen and (max-width: 950px) {
    .about-subject h2 {
        font-size: 1.2rem;
    }
    
    .about-subject p {
        font-size: 0.9rem;
    }
    
    .hours-subject {
        width: 10rem;
    }
    
    .hours-subject h2 {
        font-size: 1.2rem;
        padding-left: 1rem;
    }
    
    .hours-subject h3 {
        font-size: 1.1rem;
        padding-left: 1rem;
    }
    
    .hours-subject p {
        font-size: 0.9rem;
        padding-left: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__subjects {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .about-subject {
        width: 100%;
    }

    .hours-subject {
        width: 100%;
    }

    .hours-subject {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-left: 0;
        border-top: 0.3rem solid gray;
        flex-direction: column;
    }

    .hours-subject-bottom {
        flex-direction: row;
    }
}

@media screen and (max-width: 500px) {
    .gis__subjects {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}