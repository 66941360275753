.cookie-banner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--color-white);
    text-align: center;
    padding: 1rem;
}
  
.cookie-message {
    margin: 0 0 1rem 0;
    font-family: var(--font-family);
    font-size: 1rem;
}
  
.cookie-button-accept {
    background-color: #4CAF50;
    font-family: var(--font-family);
    font-weight: 700;
    color: white;
    border: none;
    padding: 0.75rem 1.25rem;
    margin: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 0.5rem;
}

.cookie-button-reject {
    background-color: #ac3535;
    font-family: var(--font-family);
    font-weight: 700;
    color: white;
    border: none;
    padding: 0.75rem 1.25rem;
    margin: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 0.5rem;
}
  
.cookie-button-accept:hover {
    background-color: #45a049;
}

.cookie-button-reject:hover {
    background-color: #a51f1f;
}

@media screen and (max-width: 1280px) {
    .cookie-message {
        font-size: 0.9rem;
    }
      
    .cookie-button-accept {
        font-size: 0.9rem;
    }
    
    .cookie-button-reject {
        font-size: 0.9rem;
    }
}