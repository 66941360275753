.gis__collapsible {
    width: 100%;
}

.gis__collapsible-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    height: fit-content;
    width: 100%;
    padding: 1rem 2rem;
    border: 0;
    outline: 0;
    background: var(--color-llight);
    font-family: var(--font-family);
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 0.1rem;
    color: var(--color-white);
    cursor: pointer;
}

.arrow-down {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    font-size: 2rem;
}

.arrow-top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    font-size: 2rem;
}

.gis__collapsible-btn:hover {
    background: var(--color-ldark);
    transition: 0.25s;
}

.content-parent {
    height: 0px;
    width: 100%;
    overflow: hidden;
    transition: height ease 0.5s;
}

.content-show {
    height: fit-content;
}

.gis__collapsed {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: fit-content;
    width: 100%;
    padding: 0.5rem 1.5rem;
    border-left: 0.5rem solid var(--color-llight);
    border-right: 0.5rem solid var(--color-llight);
    border-bottom: 0.5rem solid var(--color-llight);
    border-top: 0;
}

.display-none {
    display: none;
}

@media screen and (max-width: 1470px) {
    .gis__collapsible-btn {
        font-size: 1.5rem;
        padding: 0.5rem 2rem;
    }

    .arrow-down {
        font-size: 1.5rem;
    }
    
    .arrow-top {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1100px) {
    .gis__collapsible-btn {
        font-size: 1.2rem;
        padding: 0.3rem 2rem;
    }

    .arrow-down {
        padding-left: 1.5rem;
        font-size: 1.2rem;
    }
    
    .arrow-top {
        padding-left: 1.5rem;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 700px) {
    .gis__collapsed {
        flex-direction: column;
    }

    .gis__collapsible-btn {
        font-size: 1.1rem;
        padding: 0.3rem 2rem;
    }

    .arrow-down {
        padding-left: 1rem;
        font-size: 1.1rem;
    }
    
    .arrow-top {
        padding-left: 1rem;
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 500px) {
    .gis__collapsible-btn {
        font-size: 1rem;
        padding: 0.3rem 2rem;
    }
}